import React, { useEffect, useState } from 'react'
import "../css/layout.css"
import css from "../css/Profile.module.css"
import { Link, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from 'sweetalert2';
import Header from '../Components/Header';


const Profile1 = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
    }
      const [show, setShow] = useState(false);

    const [referral, setCode] = useState('')
    const [Id, setId] = useState(null)
    const [profile, setProfile] = useState()
    const [portcss, setPortcss] = useState(css.active_tab)
    const [portcss1, setPortcss1] = useState(css.inactive_tab)
    const [crushcss, setCrushcss] = useState(true)
    const [holder_name,setHolder_name]= useState();
    const [account_number,setAccount_number]=useState();
    const [ifsc_code,setIfsc_code]=useState();
    const [upi_id,setUpi_id]=useState();
    const history = useHistory()
    const logout = () => {
        let access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.post(baseUrl+`logout`, {
            headers: headers
        }, { headers })
            .then((res) => {
                // setUser(res.data)
                localStorage.removeItem("token");
                //window.location.reload();
                history.push('/login')
                window.location.reload(true);
            }).catch((e) => {
                // alert(e.msg)
            })
    }
    const UserALL = () => {
        
        let access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl+`me`, { headers })
            .then((res) => {
                setProfile(res.data)
                setId(res.data._id);
                TotalGame(res.data._id);
                setName(res.data.Name)
                setCode(res.data.referral)
                setHolder_name(res.data.holder_name);
                setAccount_number(res.data.account_number);
                setIfsc_code(res.data.ifsc_code);
                setUpi_id(res.data.upi_id);
            }).catch((e) => {
                // alert(e.msg)
            })
    }
    const [Name, setName] = useState()
    const UpdateProfile = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
            Name,
        }, { headers })
            .then((res) => {
                console.log(res.data)
                if(res.data=="User name already exist!")
                {
                    Swal.fire({
                        title: res.data,
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                }
                else{
                    setName(res.data)
                    UserALL();
                }
            })
    }
    const updateBankDetails=async (e) => {
        e.preventDefault();
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
           holder_name,account_number,ifsc_code,upi_id
        }, { headers })
            .then((res) => {
                console.log(res.data);
                if (res.status == 200) {
                    setShow(prev => !prev)
                    UserALL();
                    let message;
                    message = res.data.msg;
                    if (!(res.data.msg)) { 
                        message = 'something went wrong';
                    }
                    Swal.fire({
                        title: message,
                        icon: res.data.submit ? 'success' : 'error',
                        confirmButtonText: "OK",
                    });
                }
            })
    }
    const update_RefCode = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        const data = await axios.patch(baseUrl+`user/edit`, {
            referral,
        }, { headers })
            .then((res) => {
                console.log(res.data);
                if (res.status == 200) {
                    UserALL();
                    let message;
                    message = res.data.msg;
                    if (!(res.data.msg)) {
                        message = 'Invalid referral Code';
                    }
                    Swal.fire({
                        title: message,
                        icon: res.data.submit ? 'success' : 'error',
                        confirmButtonText: "OK",
                    });
                }
            })
    }
    //// total game
    const [total, setTotal] = useState()
    const TotalGame = (Id) => {
        let access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }

        axios.get(baseUrl+`total/user/all/${Id}`, { headers })
            .then((res) => {
                setTotal(res.data)

            }).catch((e) => {

            })
    }


    const [pic, setPic] = useState()
    const Result = async (file) => {
        if (file) {

            const access_token = localStorage.getItem("token")
            // // console.log(access_token)
            const headers = {
                Authorization: `Bearer ${access_token}`
            }

            const formData = new FormData();
            formData.append('avatar', file);


            // console.log(formData)
            await fetch(baseUrl+`users/me/avatar`, {
                method: "POST",
                body: formData,
                headers
            }).then((res) => {

                UserALL()
            })
        }


        // await axios.patch(baseUrl+`challange/result/${path}`,
        //   {
        //     status: status
        //   },
        //   { headers })
        //   .then((res) => {
        //     // getPost(res.data)
        //     // console.log(res.data);
        //     history.push("/landing")
        // }).catch((e) => {
        //     // console.log(e);
        //   })
    }
    //avatar

    useEffect(() => {
        UserALL()

    }, [])


    return (
        <>
        <Header user={profile} />
        <div className="leftContainer" style={{minHeight:'100vh'}}>

                <div className="mt-5 py-4 px-3" style={{ background: 'rgb(250, 250, 250)' }}>
                    <div className={`${css.center_xy} py-5`}>
                        <label>
                            <input className='d-none' type="file" onChange={(e) => Result(e.target.files[0])} accept="image/*" required />
                            <picture >
                                {/* {image} */}
                                {console.log(process.env.PUBLIC_URL)}
                                {profile && profile.avatar ? (<img height="80px" width="80px" src={baseUrl+`${profile && profile.avatar}`} alt="" style={{ borderRadius: "50px" }} />) : (<img height="80px" width="80px" src={process.env.PUBLIC_URL +`/user.png`} alt="" style={{ borderRadius: "50px" }} />)}
                            </picture>
                        </label>
                        <span className={`${css.battle_input_header} mr-1`}>
                            {profile && profile.Phone}
                        </span>
                        <div className={`text-bold my-3 ${portcss} font-weight-bold `} style={{ fontSize: '1em' }} >
                            {profile && profile.Name}
                            <img className={`ml-2`} width="20px" src={process.env.PUBLIC_URL + '/Images/icon-edit.jpg'} alt=""
                                onClick={() => {
                                    setPortcss(css.inactive_tab)
                                    setPortcss1(css.active_tab)
                                }}
                            />
                        </div>
                        <div className={`text-bold my-3 ${portcss1}`}>
                            <div className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`} style={{ verticalAlign: 'bottom' }}>
                                <div className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}>
                                    <input aria-invalid="false" type="text" maxLength={'20'} className={`${css.MuiInputBase_input} ${css.MuiInput_input}`} placeholder="Enter Username"
                                        value={Name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <img className="ml-2" width="20px" src={process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"} alt=""
                                onClick={() => {
                                    setPortcss(css.active_tab)
                                    setPortcss1(css.inactive_tab)
                                    UpdateProfile(Id)
                                }}
                            />
                        </div>
<Link
  className="d-flex align-items-center mt-4"
  style={{
    width: '90%',
    margin: '0 auto',
    textDecoration: 'none',
    color: '#000', // Change text color to black
    border: '2px solid #000', // Change border color to black
    borderRadius: '8px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
  }}
  to="/wallet"
  onMouseEnter={(e) => {
    e.currentTarget.style.backgroundColor = '#000'; // Background becomes black on hover
    e.currentTarget.style.color = '#fff'; // Text color becomes white on hover
    e.currentTarget.style.borderColor = '#fff'; // Border color becomes white on hover
    e.currentTarget.querySelector('img').style.transform = 'scale(1.1)';
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.backgroundColor = 'transparent'; // Background returns to transparent on leave
    e.currentTarget.style.color = '#000'; // Text color returns to black on leave
    e.currentTarget.style.borderColor = '#000'; // Border color returns to black on leave
    e.currentTarget.querySelector('img').style.transform = 'scale(1)';
  }}
>
  <picture className="ml-4">
    <img
      width="32px"
      src={process.env.PUBLIC_URL + "/Images/sidebar-wallet.png"}
      alt="Wallet Icon"
      style={{
        transition: 'transform 0.3s ease-in-out',
        filter: 'grayscale(100%)', // Apply grayscale filter to the image
      }}
    />
  </picture>
  <div
    className="ml-3"
    style={{
      fontSize: '1em',
      fontWeight: '500',
      transition: 'color 0.3s ease-in-out',
    }}
  >
    My Wallet
  </div>
</Link>
                    </div>
                </div>

                <div className={css.divider_x}>
                </div>
<div className="p-3" style={{ background: '#f4f4f4' }}>
    <div>
        <div>
            <Link 
                className={`d-flex align-items-center ${css.profile_wallet} slim-card mx-1 my-3 py-3`} 
                style={{ 
                    backgroundColor: '#fff', 
                    border: '1px solid #ccc', 
                    borderRadius: '8px', 
                    padding: '15px', 
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)', 
                    transition: 'transform 0.2s ease', 
                    width: '100%', 
                    maxWidth: '400px' 
                }} 
                to={(profile && profile.verified === 'unverified') ? `/Kyc2` : `/Profile`}
                onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.01)'}
                onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
            >
                <picture className="ml-3">
                    <img width="28px" src={process.env.PUBLIC_URL + "/Images/kyc-icon-new.png"} alt="KYC Icon" />
                </picture>
                <div className={`ml-4 ${css.mytext}`} style={{ color: '#666', fontSize: '16px', fontWeight: '500' }}>
                    {profile && profile.verified === 'unverified' 
                        ? 'KYC Pending ⏳' 
                        : profile && profile.verified === 'pending' 
                            ? 'KYC In Process' 
                            : 'KYC Completed ✅'}
                </div>
                <div className="ml-auto mr-3" style={{ display: 'flex', alignItems: 'center' }}>
                    {profile && profile.verified === 'unverified' ? (
                        <button 
                            style={{ 
                                backgroundColor: '#ff0000', 
                                color: '#fff', 
                                border: 'none', 
                                borderRadius: '4px', 
                                padding: '8px 12px', 
                                fontWeight: '600',
                                cursor: 'pointer'
                            }} 
                            onClick={() => { /* handle complete KYC logic here */ }}
                        >
                            Complete KYC
                        </button>
                    ) : profile && profile.verified === 'completed' ? (
                        <>
                            <span style={{ marginRight: '10px' }}>KYC Completed ✅</span>
                            <button 
                                style={{ 
                                    backgroundColor: '#28a745',  // Green color
                                    color: '#fff',               // White text
                                    border: 'none', 
                                    borderRadius: '4px', 
                                    padding: '8px 12px', 
                                    fontWeight: '600',
                                    cursor: 'default'
                                }} 
                                disabled
                            >
                                Verified  {/* Button text set to "Verified" */}
                            </button>
                        </>
                    ) : null} {/* No output for "KYC In Process" */}
                </div>
            </Link>
        </div>
    </div>
</div>
              
                <div className={css.divider_x}>
                </div>
                {/*{profile && !profile.referral && <div className="px-3 py-1">
                    <div className={`d-flex align-items-center ${css.position_relative}`} style={{ height: '84px' }}>
                        <picture>
                            <img height="32px" width="32px" src={process.env.PUBLIC_URL + 'Images/Header/sreferEarn.png'} alt="" className="snip-img" />
                        </picture>
                        <div className="pl-4">
                            <div className={`${css.text_uppercase} ${css.moneyBox_header}`} style={{ fontSize: '1rem' }}>
                                Use Refer Code
                            </div>
                            <div className="d-flex">
                                
                                <input type="text" className={css.MuiInputBase_input} aria-describedby="basic-addon1" value={referral} onChange={(e) => { setCode(e.target.value) }} />
                                <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => {
                                    update_RefCode()
                                }}>
                                    <img width="20px" className="ml-2" src={process.env.PUBLIC_URL + "/Images/select-blue-checkIcon.png"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}*/}
                
<div className="d-flex flex-wrap justify-content-between">
  {/* Cash Won */}
  <div className="px-2 py-2" style={{ flex: "0 0 48%" }}>
    <div className="d-flex align-items-center" style={{ height: '70px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', backgroundColor: '#f0f4f7' }}>
      <svg height="36" width="36" viewBox="0 0 64 64" fill="green">
        <circle cx="32" cy="32" r="30" />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="18" fill="#fff">₹</text>
      </svg>
      <div className="pl-3">
        <div style={{ fontSize: '0.9em', fontWeight: '600', textTransform: 'uppercase' }}>
          Cash Won
        </div>
        <div>
          <span style={{ fontSize: '0.9em' }}>
            {profile && profile.wonAmount}
          </span>
        </div>
      </div>
    </div>
  </div>

  {/* Battle Played */}
  <div className="px-2 py-2" style={{ flex: "0 0 48%" }}>
    <div className="d-flex align-items-center" style={{ height: '70px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', backgroundColor: '#f0f4f7' }}>
      <svg height="36" width="36" viewBox="0 0 64 64" fill="purple">
        <circle cx="32" cy="32" r="30" />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="18" fill="#fff">B</text>
      </svg>
      <div className="pl-3">
        <div style={{ fontSize: '0.9em', fontWeight: '600', textTransform: 'uppercase' }}>
          Battle Played
        </div>
        <div>
          <span style={{ fontSize: '0.9em' }}>
            {total && total}
          </span>
        </div>
      </div>
    </div>
  </div>

  {/* Referral Earning */}
  <div className="px-2 py-2" style={{ flex: "0 0 48%" }}>
    <div className="d-flex align-items-center" style={{ height: '70px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', backgroundColor: '#f0f4f7' }}>
      <svg height="36" width="36" viewBox="0 0 64 64" fill="blue">
        <circle cx="32" cy="32" r="30" />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="18" fill="#fff">R</text>
      </svg>
      <div className="pl-3">
        <div style={{ fontSize: '0.9em', fontWeight: '600', textTransform: 'uppercase' }}>
          Referral Earning
        </div>
        <div>
          <span style={{ fontSize: '0.9em' }}>
            {profile && profile.referral_earning}
          </span>
        </div>
      </div>
    </div>
  </div>

  {/* Penalty */}
  <div className="px-2 py-2" style={{ flex: "0 0 48%" }}>
    <div className="d-flex align-items-center" style={{ height: '70px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', backgroundColor: '#f0f4f7' }}>
      <svg height="36" width="36" viewBox="0 0 64 64" fill="red">
        <circle cx="32" cy="32" r="30" />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="18" fill="#fff">P</text>
      </svg>
      <div className="pl-3">
        <div style={{ fontSize: '0.9em', fontWeight: '600', textTransform: 'uppercase' }}>
          Penalty
        </div>
        <div>
          <span style={{ fontSize: '0.9em' }}>
            0
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
                
                <div className={css.divider_x}>
                </div>
                <div className="p-3 snipcss-A1eLC snip-div">
                    <Link to="/login" className={`${css.center_xy}  text-uppercase py-4 font-weight-bolder text-muted`} onClick={(e) => logout(e)}>
                        Log Out
                    </Link>
                </div>
            </div>
            <div className='rightContainer'>
                <Rightcontainer />
            </div>
            {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
            <div className={css.kyc_select} id="profileModal" >
                <div className={css.overlay} />
                <div
                    className={`${css.box}`}
                    style={{
                        bottom: '0px',
                        position: 'absolute',
                    }}
                >
                    <div className={css.bg_white}>
                        <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
                            <picture>
                                <img
                                    height="80px"
                                    width="80px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                                    alt=""
                                />
                            </picture>
                            <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
                        </div>
                        <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
                            <div className="row justify-content-between col-10 mx-auto">
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                                    alt=""
                                />
                            </div>
                            <div className="row justify-content-between col-10 mx-auto mt-3">
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                                    alt=""
                                />
                                <img
                                    height="50px"
                                    width="50px"
                                    src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Profile1;
